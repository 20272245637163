.chip-link {
  display: flex;
  gap: 0.5em;
  text-decoration: none;
  color: var(--bs-primary);

  &:hover {
    color: color-mix(in srgb, var(--bs-primary), black 20%);
  }

  &:hover {
    .chip {
      background-color: var(--bs-primary);
      color: white;
    }
  }

  &__text {
    margin-top: 0.4em;
  }
}

.link-list {
  display: flex;
  flex-wrap: wrap;
  flex-flow: column;
  gap: 0.5em;
}
