.alert {
  border: 1px solid var(--bs-info);
  background: alpha(var(--bs-info), 0.2);
  color: var(--bs-info);
  border-radius: 7px;
  padding: 13px;
  font-weight: 600;
  font-size: 0.9rem;

  &--info {
    a {
      color: var(--bs-info);
      text-decoration: underline;

      &:hover {
        color: color-mix(in srgb, var(--bs-info), black 20%);
      }
    }
  }

  &--danger {
    border-color: var(--bs-danger);
    background: alpha(var(--bs-danger), 0.15);
    color: var(--bs-danger);
  }

  &--warning {
    border-color: var(--bs-warning);
    background: alpha(var(--bs-warning), 0.15);
    color: var(--bs-warning);
  }
  &--success {
    border-color: var(--bs-secondary);
    background: alpha(var(--bs-secondary), 0.05);
    color: var(--bs-secondary);
    fill: var(--bs-secondary);
  }
}
