.disease-panel {
  background-color: var(--bs-primary-200);
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  padding: 20px;
  align-items: center;

  .chip {
    background-color: var(--bs-primary);
    color: white;
  }
}

.mat-mdc-option.mdc-list-item:nth-child(odd) {
  background-color: var(--bs-primary-200);

  &:hover:not(.mdc-list-item--disabled),
  &.mat-mdc-option-active,
  &:focus {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.search-field {
  --mdc-outlined-text-field-outline-color: #a9a9a9;
  --mdc-outlined-text-field-container-shape: 5px;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--bs-primary);

  .mat-mdc-form-field-flex {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: var(--mdc-outlined-text-field-container-shape);
  }

  .mdc-text-field {
    border-radius: 5px;
  }
  .mat-mdc-form-field-icon-prefix {
    margin-left: 20px;
    margin-right: 12px;
    fill: var(--bs-gray-500);

    fstr-icon {
      width: 1.3em;
      height: 1.3em;
    }
  }

  .mat-mdc-form-field-icon-suffix {
    margin-right: 9px;
  }
}

.text-gray-700 {
  color: var(--bs-gray-700);
}

.select-disease-option {
  .mdc-list-item__primary-text {
    display: flex;
    flex-flow: column;
  }
}

.mat-mdc-autocomplete-panel.mat-mdc-autocomplete-hidden:empty {
  display: none;
}
