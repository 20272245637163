.chip {
  --chip-size: 1.5em;
  --chip-icon-size: 1em;

  border: 1px solid transparent;
  color: var(--bs-primary);
  border-radius: 50%;
  @include size(var(--chip-size));
  min-width: var(--chip-icon-size);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  transition: all 0.2s;
  background-color: var(--bs-primary-100);
  flex-shrink: 0;

  &.is--active,
  &:hover {
    background-color: var(--bs-primary);
    color: white;
  }

  &,
  app-icon,
  fstr-icon {
    .icon {
      @include size(var(--chip-icon-size));
      min-width: var(--chip-icon-size);
      margin: auto;
      fill: currentColor;
    }
  }
}

.chip--outline {
  border: 1px solid var(--bs-primary);
  background-color: transparent;

  &.is--active,
  &:hover {
    background-color: var(--bs-primary);
    color: white;
    fill: white;
  }
}

@each $name, $value in $button-sizes {
  .chip--#{$name} {
    --chip-size: #{rem($value)};
    //--chip-font-size: #{map-get($font-sizes, 'body-' + $name)};
    --chip-icon-size: #{rem(map-get($icon-sizes, $name) * 1)};
  }
}
