.button-icon {
  @include size(56px);
  background-color: var(--bs-muted);
  border-radius: rem(200px);
  display: flex;
  justify-content: center;
  padding: 0;
  transition: background 0.35s ease;

  app-icon,
  fstr-icon {
    pointer-events: none;

    .icon,
    & {
      @include size(1em);
    }
  }

  &:hover {
    fill: lightenColor(var(--bs-dark), 0.5);
  }
}
