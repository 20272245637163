.diseases-graph {
  pre {
    font-size: 12px;
  }

  &__language {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding-top: 2rem;

    &__label {
      text-transform: uppercase;
      font-size: 0.8em;
    }
    &__flag {
      border-radius: 50%;
      border: 5px solid rgba(189, 211, 255, 0.3215686275);
      width: 42px;
      height: 42px;
      object-fit: cover;
    }
  }

  &__chart {
    // height: 80vh;
    // min-height: 440px;
    //height: auto;
    // height: 50vh;
    width: 100%;
    transition: height 0.3s ease-in-out;
    pointer-events: none;
  }
}
