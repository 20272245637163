.emergency-graph {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  background-color: var(--bs-gray-150);
  height: 100%;
  border-radius: 4px;
}
.emergency-graph__chart {
  min-height: 640px;

  @include media-breakpoint-down(md) {
    min-width: 600px;
    min-height: 400px;
    height: 60vh;
  }
}
