// Functions
@import 'functions/all';

// Config
@import 'config';

// Mixins
@import 'mixins/all';

// Vendor
@import 'material/all';

// Applying config to base tags etc.
@import 'base/all';
@import 'themes/all';

@import 'utils/all';
@import 'components/all';

// quill
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';

// Angular components
@import 'angular-modules.scss';

//body {
//  @include scrollbar();
//}
