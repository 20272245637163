.button {
  &--outline {
    color: var(--bs-primary);
    border: 2px solid var(--bs-primary);
    background: 0;
    &:hover {
      background: white !important;
      opacity: 0.7;
    }
  }
  @extend %button-reset;

  display: inline-flex;
  position: relative;
  border-radius: $border-radius;
  height: rem($button-size);
  align-items: center;
  padding-right: rem($button-padding-x);
  padding-left: rem($button-padding-x);
  overflow: hidden;
  font-family: $font-family-sans-serif;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  transition: all 200ms ease-in-out;
  background-color: var(--bs-muted);
  color: white;

  @include media-breakpoint-up(md) {
    height: rem($button-size * 1.2);
    padding-right: rem($button-padding-x * 1.2);
    padding-left: rem($button-padding-x * 1.2);
  }

  span + app-icon,
  app-icon + span,
  span + fstr-icon,
  fstr-icon + span {
    margin-inline-start: 0.5ex;
  }

  .icon * {
    fill: white;
    color: white;
    transition: fill 0.2s;
  }

  @media (hover: hover) {
    &:hover {
      background-color: darkenColor(var(--bs-muted), 0.2);
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

// Icons in buttons
.button {
  --icon-inset-factor: -0.5;

  span + fstr-icon,
  span + app-icon,
  span + .mat-icon {
    margin-inline-start: rem(8px);
  }

  .icon-chip + span,
  app-icon + span,
  fstr-icon + span,
  .mat-icon + span {
    margin-inline-start: rem(8px);
  }

  fstr-icon .icon,
  app-icon .icon {
    fill: currentColor;
  }
}
