.statistics-overview {
  max-width: 100vw;
  overflow: hidden;

  .button-link {
    fstr-icon {
      min-width: 10px;
      min-height: 10px;
      transition: transform 0.3s ease-in-out;
    }

    &.set--active {
      fstr-icon {
        transform: rotate(180deg);
      }
    }
  }
}
.graph-legend {
  @include media-breakpoint-down(md) {
    max-height: 0px;
    overflow: hidden;
    transition:
      max-height 0.6s cubic-bezier(0, 1, 0, 1),
      opacity 0.3s ease-in-out;
    opacity: 0;

    &.show--legend {
      max-height: 1000px;
      transition:
        max-height 0.6s ease-in-out,
        opacity 0.3s ease-in-out;
      opacity: 1;
    }
  }

  &__color {
    @include size(24px);
    border-radius: 50%;
    background-color: currentColor;
    display: block;
  }
}

.graph-wrapper {
  @include media-breakpoint-down(md) {
    background-color: var(--bs-gray-150);
    height: 100%;
  }
}
