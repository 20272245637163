.mat-mdc-form-field {
  width: 100%;
}

input[type='date']::-webkit-calendar-picker-indicator {
  display: block !important;
}

//.mat-mdc-form-field {
//  --mdc-filled-text-field-input-text-color: var(--bs-body-color);
//  --mdc-filled-text-field-label-text-color: var(--bs-body-color);
//  --mdc-filled-text-field-input-text-placeholder-color: var(--bs-secondary-color);
//}
