
@each $name, $value in $button-sizes {
  .button--#{$name} {
    height: rem($value);
    padding-right: rem($value * 0.5);
    padding-left: rem($value * 0.5);

    @include get-font-size(body-#{$name});

    &.button-link {
      padding-left: 0;
      padding-right: 0;
    }

    &.button-icon {
      @include size(rem(map-get($icon-sizes, $name) * 2));

      .icon, app-icon {
        @include size(rem(map-get($icon-sizes, $name)));
        min-width: rem(map-get($icon-sizes, $name));
      }
    }
  }
}