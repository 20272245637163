// Imports

// Bootstrap
$link-color: #2db5c1;
$font-size-base: 16;

$font-family-sans-serif: 'open-sans', sans-serif;
$font-family-serif: 'open-sans', serif;

// Tip: Use goldenratio to generate font base sizes
// https://type-scale.com/
$font-sizes: (
  1: 48px,
  2: 36px,
  3: 32px,
  4: 24px,
  5: 20px,
  6: 16px,
  body-xxs: 12px,
  body-xs: 14px,
  body-sm: 16px,
  body-md: 20px,
  body-lg: 26px,
);

$headings-font-family: $font-family-serif;
$headings-font-weight: 600;

$font-family-base: $font-family-sans-serif;
$font-weight-base: 300;

$lead-font-size: map-get($font-sizes, body-md);
$lead-font-weight: 300;

$text-muted: var(--bs-gray-600);

// Other
//? Put own extra variables here
