@each $name, $colors in $disease-group-themes {
  .theme-#{$name} {
    $primary: map-get($colors, primary);
    $secondary: map-get($colors, secondary);

    --bs-primary: #{$primary};
    --bs-primary-rgb: #{to-rgb($primary)};
    --bs-secondary: #{$secondary};
    --bs-secondary-rgb: #{to-rgb($secondary)};
  }
}
